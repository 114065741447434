/* You can add global styles to this file, and also import other style files */
.dataTables_empty {
  display: none;
}
.dataTables_wrapper .dataTables_filter input {
  border-radius: 30px !important;
}


.dataTables_wrapper .dataTables_paginate .paginate_button.current{
border: none !important;

  background-color:  #0d6efd  !important ;
  color: white !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover{
  border: none !important;
  
      background: linear-gradient(to bottom, #f1f1f1 0%, #fff) !important ;
  
}


.dataTables_wrapper .dataTables_filter input{
      margin-right: -16px;
}

body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

@media screen and (max-width: 639px) {
  .dataTables_wrapper .dataTables_length,
  .dataTables_wrapper .dataTables_filter {
    display: block;
    margin-bottom: -13px; /* Vous pouvez ajuster la marge selon vos besoins */
  }
}
#dtable_paginate{
  width:100%;
}
@media only screen and (max-width: 640px) {
  .dataTables_length, .dataTables_filter {
    display: inline-block !important;
    margin-right: 10px;
  }

  .dataTables_filter {
    float: right !important;
  }
  .dataTables_length{
    margin-top: 13px !important;
  }
}
#dtable_filter{
  margin-right: 19px;
}
